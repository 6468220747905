import React from 'react'
import { BulletList } from './BulletList'

const whatWillILearn = [
  'What developers want, and what they think about jobs and recruiters',
  'What makes developers respond (or not) to recruiters',
  'Frontend vs Backend',
  'How software projects are run',
  'Job descriptions and titles of people involved in making software',
  'Tech stack deep dives (Java Spring, MEAN, .NET, Ruby on Rails, and more)',
  'How common websites and apps work',
  'DevOps and advanced topics like Docker and Kafka',
]

const whatDoIGet = [
  'Lifetime access to the video course',
  'Free Updates when new content is added to the course',
  'Email templates, subject lines, and real cold email examples',
  'Over 70 videos, more than 5.5 hours of video content',
  '100% 30 day Money Back Guarantee if you are not satisfied with the content',
]

export const Features = () => {
  return (
    <div className='section'>
      <h2 style={{ marginBottom: '2rem' }} className='has-text-primary is-size-2 has-text-centered has-margin-title'>
        Features of the main course
      </h2>
      <div className='columns'>
        <div className='column is-6'>
          <h2 className='has-text-info'>What Will I Learn?</h2>
          <div>
            <BulletList items={whatWillILearn} />
          </div>
        </div>
        <div className='column is-6 is-offset-1'>
          <h2 className='has-text-info'>What Do I get</h2>
          <div>
            <BulletList items={whatDoIGet} />
          </div>
        </div>
      </div>
    </div>
  )
}
