import React from 'react'
import styled from 'styled-components'
import { lighter } from '../constants/theme'

const Svg = styled.svg`
  display: "inline-flex";
  align-self: "center";
  width: 16px;
  height: 16px;
  top: 0.125em;
  position: relative;
`

export const CheckMark = ({ fill = lighter }) => {
  return (
    <Svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 125' version='1.1'>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g fill={fill}>
          <path d='M50.5,98 C76.7335269,98 98,76.7335269 98,50.5 C98,24.2664731 76.7335269,3 50.5,3 C24.2664731,3 3,24.2664731 3,50.5 C3,76.7335269 24.2664731,98 50.5,98 L50.5,98 Z M72.6666667,25.1666667 L41,56.8333333 L28.3333333,44.1666667 L22,56.8333333 L41,75.8333333 L79,37.8333333 L72.6666667,25.1666667 L72.6666667,25.1666667 Z' />
        </g>
      </g>
    </Svg>
  )
}
