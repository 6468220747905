import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import Img from "gatsby-image";

const WrapperBackground = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Inner = styled.div`
  margin: 1rem;
`;

const H3Small = styled.h3`
  margin-top: 0;
`;

export const MeetAaron = ({ fullpage }) => {
  const data = useStaticQuery(graphql`
    query {
      headshot: file(relativePath: { eq: "headshot_beer_small.jpg" }) {
        childImageSharp {
          fixed(height: 175, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  return (
    <WrapperBackground>
      <Inner style={{ flex: "1 1 125px", textAlign: "center" }}>
        <Img
          fixed={data.headshot.childImageSharp.fixed}
          alt="Aaron Decker Headshot"
        />
      </Inner>
      <Inner
        style={{
          flex: fullpage === true ? "1 1 800px" : "1 1 400px",
          minWidth: "250px",
        }}
      >
        <H3Small className="has-text-info">Meet The Author</H3Small>
        <p>
          I'm Aaron Decker, a full stack developer with about 10 years of
          experience writing code. Currently, I'm a cofounder at{" "}
          <a
            href="https://www.bounty.co"
            target="_black"
            rel="noopen noreferrer"
          >
            Bounty
          </a>
          where I serve as head of engineering. Previously, I have worked as a
          Tech Lead, hired teams, and as a Senior Software Engineer at multiple
          fortune 500 companies building large products. I also did a brief
          stint teaching programming courses as an Adjunct Instructor at a local
          community college.
        </p>
        <p>
          These courses draw on my experience working in the industry and
          teaching teaching technical topics. You can reach me directly at{" "}
          <a href="mailto:aaron@ard.ninja">aaron@ard.ninja</a>
          {", "}
          <a
            href="https://www.linkedin.com/in/aaron-r-decker/"
            target="_black"
            rel="noopen noreferrer"
          >
            Linkedin
          </a>
          , or Twitter{" "}
          <a
            href="https://twitter.com/dynamicdoublea"
            target="_black"
            rel="noopen noreferrer"
          >
            @dynamicdoublea
          </a>
          .
        </p>
      </Inner>
    </WrapperBackground>
  );
};
