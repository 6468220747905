import React from 'react'
import { Link } from 'react-scroll'

export const ViewCourseOfferings = () => {
  return (
    <div>
      <Link to='courseofferings' offset={-50}>
        <span className='button is-info is-large'>View course offerings!</span>
      </Link>
    </div>
  )
}
