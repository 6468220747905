import React from 'react'
import { BulletList } from './BulletList'
import { Link } from 'gatsby'
import styled from 'styled-components'

const Strong = styled.span`
  font-weight: bold;
`

export const SalesPitch = () => {
  return (
    <div className='section'>
      <div className='columns'>
        <div className='column is-6'>
          <h2 className='has-text-info'>Domain Knowledge is Crucial!</h2>
          <p>
            Domain knowledge is a key to success in recruiting{' '}
            <Link to='/blog/what-is-the-value-of-technical-domain-knowledge-for-recruiters/'>
              according to one prominent author of recruiting books
            </Link>
            , and even more so in the complex and swiftly changing field of
            software.
          </p>
          <p>
            Learning the ins and outs of how software is made is a difficult
            process. It's hard to get a complete understanding, especially if
            you don't know which crucial pieces of the puzzle you are missing.
          </p>
          <p />
          <p>
            You can learn tech topics somewhere else, but I am building this
            course specifically for recruiters and it will be curated all in one
            place for you.
          </p>
          <p>
            Isn't it worth investing the time to learn about the industry if it
            could drastically improve your response rates?
          </p>
        </div>
        <div className='column is-6 is-offset-1'>
          <h2 className='has-text-info'>
            Making better connections with candidates.
          </h2>
          <p>
            In the{' '}
            <Link to='/blog/are-developers-hesitant-to-work-with-tech-recruiters/'>
              first survey I ran with developers about recruiters
            </Link>
            , one person responded:
          </p>
          <blockquote>
            Recruiters probably should have industry experience. There’s too
            much of a disconnect.
          </blockquote>
          <p>
            Even worse, in the{' '}
            <Link to='/blog/january-2020-developer-survey-on-recruiting/'>
              most recent survey
            </Link>{' '}
            I ran,{' '}
            <Strong>
              45% of developers said they had a "Generally Negative"
            </Strong>
            opinion about working with recruiters.
          </p>
          <p>
            The best way to connect with developers is to understand what they
            do. Making a real connection with candidates takes their shields
            down and gives you a chance to do you job.
          </p>
        </div>
      </div>
    </div>
  )
}
