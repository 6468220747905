import React from 'react'
import Helmet from 'react-helmet'
import { graphql, useStaticQuery, Link } from 'gatsby'
import PropTypes from 'prop-types'
import { SubscribeForm } from '../common/subscribeForm'
import { Features } from '../common/features'
import { MeetAaron } from '../common/meetaaron'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { CheckMark } from '../common/checkmark'
import { SalesPitch } from '../common/salespitch'
import { SalesPitch2 } from '../common/salespitch2'
import CourseOfferingsPanel from '../common/courseOfferingsPanel'
import { ViewCourseOfferings } from '../common/viewCourseOfferings'
import Reviews from '../common/whatHavePeopleSaid'

const PadDiv = styled.div`
  margin-top: 2rem;
`

const CtaDiv = styled.div`
  margin-bottom: 4rem;
  text-align: center;
`

const HeroList = styled.ul`
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1rem;

  li {
    display: flex;
  }
  svg {
    margin-right: 0.5rem;
    align-self: center;
  }
`

const FooterMessage = styled.div`
  margin: 1rem;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 900;
`

const LogoWrapper = styled.div`
  max-width: 300px;
  text-align: center;
  margin: auto;
  margin-top: 1rem;

  @media (max-width: 500px) {
    display: none;
  }
`

const NonStretchedImage = props => {
  return <Img {...props} />

  // let normalizedProps = props;
  // if (props.fluid && props.fluid.presentationWidth) {
  //   normalizedProps = {
  //     ...props,
  //     style: {
  //       ...(props.style || {}),
  //       maxWidth: props.fluid.presentationWidth,
  //       margin: "0 auto" // Used to center the image
  //     }
  //   };
  // }

  // return <Img {...normalizedProps} />;
}

const HomePageTemplate = ({
  title,
  heading,
  description,
  meta_title,
  meta_description,
  testimonials,
}) => {
  const data = useStaticQuery(graphql`
    query HomepageQuery {
      logoFile: file(relativePath: { eq: "logo_white_background.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
      presentationImage: file(
        relativePath: { eq: "presenting-to-recruiters-of-minnesota-1200.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
    }
  `)

  return (
    <div>
      <Helmet>
        <title>{meta_title}</title>
        <meta name='description' content={meta_description} />
        <link rel='canonical' href='https://www.iteachrecruiters.com' />
      </Helmet>
      <section className='hero is-primary is-bold'>
        <div className='hero-body'>
          <div className='container'>
            <div className='columns'>
              <div className='column is-6 is-offset-1'>
                <h1 className='title has-text-white is-size-1'>{title}</h1>
                <h2 className='description has-text-white is-size-3'>
                  Tired of software engineers ignoring your messages? 
                  Learn the jargon you need to know to make a real connection!
                </h2>
                <HeroList>
                  <li>
                    <CheckMark fill='#ffffff' />
                    Increase your response rate
                  </li>
                  <li>
                    <CheckMark fill='#ffffff' />
                    Make more placements
                  </li>
                  {/* <li>
                    <CheckMark fill="#ffffff" />
                    Understand requirements better from hiring managers
                  </li> */}
                  <li>
                    <CheckMark fill='#ffffff' />
                    Speak to engineers in their own language
                  </li>
                </HeroList>

                <PadDiv>
                  <ViewCourseOfferings />
                </PadDiv>
              </div>
              <div className='column is-5'>
                {/*  */}
                <NonStretchedImage
                  alt='Presenting to Recruiters of Minnesota'
                  fluid={data.presentationImage.childImageSharp.fluid}
                />
                <LogoWrapper>
                  <NonStretchedImage
                    alt='ITeach Recruiters Logo'
                    fluid={data.logoFile.childImageSharp.fluid}
                  />
                </LogoWrapper>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='section section--gradient'>
        <div className='container'>
          <div className='columns'>
            <div className='column is-10 is-offset-1'>
              <div className='content'>
                {/* <div>
                    <h3 className="has-text-weight-semibold is-size-2">
                      {heading}
                    </h3>
                    <p>{description}</p>
                  </div> */}
                <SalesPitch2 />
                <SalesPitch />
                <CtaDiv>
                  <ViewCourseOfferings />
                </CtaDiv>
                <MeetAaron fullpage />
                <hr />
                <Features />
                <Reviews />
                <CourseOfferingsPanel />
                <SubscribeForm />
                <FooterMessage>
                  Still not sure? Check our <Link to='/about'>FAQ</Link> or{' '}
                  <Link to='/contact'>Contact</Link> Us if you need more
                  information.
                </FooterMessage>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

HomePageTemplate.propTypes = {
  title: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  testimonials: PropTypes.array,
}

export default HomePageTemplate
