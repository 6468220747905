import React from 'react'
import { BulletList } from './BulletList'
import { Link } from 'gatsby'
import styled from 'styled-components'

const Strong = styled.span`
  font-weight: bold;
`

/**
 * This is an updated sales pitch
 *
 */

export const SalesPitch2 = () => {
  return (
    <div className='section'>
      <div className='columns'>
        <div className='column is-6'>
          <h2 className='has-text-info'>Improving Response Rates</h2>
          <p>
            <Strong>
              The number one reason recruiters don't get a response is because
              of mis-targeting.
            </Strong>
          </p>
          <p>
            The{' '}
            <Link to='/blog/january-2020-developer-survey-on-recruiting/'>
              latest developer survey
            </Link>{' '}
            I ran in January, shows clearly that recruiters could easily improve
            their response rates.
          </p>
          <p>
            The easiest thing you can do as a recruiter is to simply learn the
            technology to better qualify candidates.
          </p>

          <h3>My course covers these essential topics:</h3>
          <BulletList
            noBullet={false}
            items={[
              'The Software Development Lifecycle',
              'Basic Client/Server Architecture',
              'Tech Stacks',
              'Frontend Vs Backend',
              'Databases',
            ]}
          />
          <p style={{ marginTop: '1rem' }}>
            Normally it takes months (if not years) to pick up on this essential
            domain knowledge. Lucky for you I have packaged it all into one
            place in a course designed specifically for technical recruiters.
          </p>
        </div>
        <div className='column is-6 is-offset-1'>
          <h2 className='has-text-info has-text-centered'>Survey Question:</h2>
          <img
            alt='why dont developers respond most often'
            src='/img/why-dont-you-respond-most-often-home.png'
          />
        </div>
      </div>
    </div>
  )
}
